<template>
    <video class="bg-hero" playsinline webkit-playsinline muted :poster='this.$pathprod+"images/poster/saintOuen-poster.jpg"' preload>
        <source :src='this.$pathprod+"videos/entete/saint_ouen_rhapsody.mp4"' type="video/mp4">
        <source :src='this.$pathprod+"videos/entete/saint_ouen_rhapsody.webm"' type="video/webm">
    </video>
</template>
<script>
    export default {
        name: 'VideoSaintOuen',
        props: {
            msg: String,
        },
    }
</script>